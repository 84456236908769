import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  OktaAuth,
  OktaAuthOptions,
  TokenManagerInterface,
  AccessToken,
  IDToken,
  UserClaims,
  TokenParams
} from '@okta/okta-auth-js';
import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from './app.module';
import { AuthActions } from './modules/auth/redux/auth.actions';
import { OktaService } from './shared/service/okta-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'webbi';
  checkAuthTimerHandle: number;
  authClient: OktaAuth;
  tokenManager: TokenManagerInterface;

  constructor(
    public router: Router,
    private ngRedux: NgRedux<IAppState>,
    private authActions: AuthActions
  ) {}

  async ngOnDestroy(): Promise<void> {
    if (this.checkAuthTimerHandle) {
      clearInterval(this.checkAuthTimerHandle);
    }
    if (this.authClient) {
      await this.authClient.stop();
    }
  }

  async ngOnInit(): Promise<void> {}

  setUpAuthCheck() {
    if (this.checkAuthTimerHandle === undefined) {
      this.checkAuthTimerHandle = <any>setInterval(() => {
        if (this.tokenManager) {
          // @ts-ignore
          this.ngRedux.dispatch(this.authActions.checkJwtRefreshAsync(true));
        }
      }, 60000);
      // if (this.tokenManager) {
      //   this.ngRedux.dispatch(this.authActions.validateSession());
      // }
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './modules/layout/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './modules/auth/auth-layout.component';
import { AuthGuard } from './core/service/auth-guard.service';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/layout/admin-layout/admin-layout.module').then(
            (m) => m.AdminLayoutModule
          )
      },
      {
        path: 'courses',
        loadChildren: () => import('./modules/courses/courses.module').then((m) => m.CoursesModule)
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./modules/accounts/accounts.module').then((m) => m.AccountsModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule)
      },
      {
        path: 'utilities',
        loadChildren: () =>
          import('./modules/utilities/utilities.module').then((m) => m.UtilitiesModule)
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
      }
    ]
  },
  { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes)],
  exports: []
})
export class AppRoutingModule {}

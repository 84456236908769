export const INITIAL_STATE: ICoursesState = {
  chapters: undefined,
  courses: undefined,
  currentCourse: undefined,
  currentQuestion: undefined,
  currentPage: undefined,
  error: undefined,
  loading: false,
  pages: undefined,
  pagesFiltered: undefined,
  pagesParameters: {
    search: '',
    showing: 'all',
    sortField: 'sequence_no',
    sortDirection: 'asc',
    start: 0,
    limit: 10
  },
  message: undefined,
  publishing: false,
  questions: undefined,
  questionsFiltered: undefined,
  questionsParameters: {
    search: '',
    showing: 'all',
    sortField: 'attached',
    sortDirection: 'asc',
    start: 0,
    limit: 50
  },
  saving: false,
  sections: undefined
};

export interface ICoursesState {
  chapters: Chapter[];
  courses: Course[];
  currentCourse: string;
  currentQuestion: string;
  currentPage: string;
  error: string;
  loading: boolean;
  pages: Page[];
  pagesFiltered: Page[];
  pagesParameters: IParameters;
  message: string;
  publishing: boolean;
  questions: Question[];
  questionsFiltered: Question[];
  questionsParameters: IParameters;
  saving: boolean;
  sections: Section[];
}

export interface IParameters {
  search: string;
  showing: string;
  sortField: string;
  sortDirection: string;
  start: number;
  limit: number;
}

export interface Course {
  lineItems: string;
  combo: boolean;
  active: boolean;
  description: {
    en: string;
    es: string;
  };
  id: string;
  image: string;
  price: string;
  state: string;
  title: {
    en: string;
    es: string;
  };
  en: string;
  es: string;
  useFinalForReinforcement: boolean;
  hasSecondFinal: boolean;
  finalScoreToPass: number;
  passingReinforcementRequired: boolean;
  useSecurityQuestions: boolean;
  captureSecondaryProfileIdentity: number;
}

export interface Chapter {
  en: string;
  order: number;
  section: Section;
  id: string;
  course: string;
  es: string;
}

export interface Section {
  course: string;
  en: string;
  id: string;
  es: string;
  order: number;
}

export interface Page {
  id: string;

  description: string;

  course: string;

  en_contents: string;
  en_slug: string;
  en_title: string;

  es_contents: string;
  es_slug: string;
  es_title: string;

  publish_date_utc: string;
  document_date_utc: string;
  revision_no: string;
  site_id: string;

  image: string;
  image_position: string;

  chapter: Chapter;
  sequence_no: number;
  tags: string[];

  status: string;
  uuid_key: string;
}

export interface Chapter {
  id: string;
  course: string;
  en: string;
  es: string;
}

export class Question {
  id: string;
  chapter: Chapter;
  section: Chapter;

  status: string;
  type: string;
  attached: string;

  typeCoursePlacementIndex: string;

  en: QuestionModel;
  es: QuestionModel;

  en_question: string;
  en_correct: string;
  en_incorrect_1: string;
  en_incorrect_2: string;
  en_incorrect_3: string;
  en_explanation: string;

  es_question: string;
  es_correct: string;
  es_incorrect_1: string;
  es_incorrect_2: string;
  es_incorrect_3: string;
  es_explanation: string;
}

export class QuestionModel {
  question: string;
  correct: string;
  incorrect_1: string;
  incorrect_2: string;
  incorrect_3: string;
  explanation: string;
}

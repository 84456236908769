const UtilityTypes = {
  SET_DOTS: 'SET_DOTS',
  INCREMENT_DOT: 'INCREMENT_DOT',
  EXPORT_ADD: 'EXPORT_ADD',
  EXPORT_REMOVE: 'EXPORT_REMOVE',
  IMPORT_ADD: 'IMPORT_ADD',
  IMPORT_REMOVE: 'IMPORT_REMOVE',
  BACKUP_STATUS: 'BACKUP_STATUS'
};

export default UtilityTypes;

import { createSelector } from 'reselect/lib';
import { IAuthState } from './auth.state';

const selectAuth = (state): IAuthState => state.auth;

export const selectUser = createSelector([selectAuth], (auth) => auth.user);

export const selectOktaClient = createSelector([selectAuth], (auth) => auth.oktaClient);

export const selectUserName = createSelector([selectAuth], (auth) => auth.user.name);

export const selectEmail = createSelector([selectAuth], (auth) => auth.user.email);

export const selectLanguage = createSelector([selectAuth], (auth) => auth.language);

export const selectIsLoggedIn = createSelector([selectAuth], (auth) => auth.loggedIn);

export const selectTargetSite = createSelector([selectAuth], (auth) => auth.targetSite);

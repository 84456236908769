import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { OktaAuth } from '@okta/okta-auth-js';

interface CognitoUser {
  attributes: {
    name: string;
    email: string;
  };
  birthdate: string;
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  phone_number: string;
  phone_number_verified: boolean;
  sub: string;
}

export interface IAuthState {
  oktaClient: OktaAuth;
  user: CognitoUser;
  claims: IClaims;
  loggedIn: boolean;
  language: string;
  targetSite: string;
}

export const INITIAL_STATE: IAuthState = {
  oktaClient: undefined,
  user: undefined,
  claims: undefined,
  loggedIn: false,
  language: 'en',
  targetSite: 'prod'
};

export interface ILogin {
  claims: IClaims;
  me: IMe;
}

export interface IClaims {
  email: string;
  employer_id: string;
  employer_name: string;
  first_name: string;
  is_manager: boolean;
  language: string;
  last_name: string;
  name: string;
  profile_pic: string;
  sub: string;
  subscriber_id: string;
  token: string;
}

export interface IMe {
  birth_date: string;
  birth_year: string;
  created_at: number;
  email: string;
  email_verified: boolean;
  employer: string;
  employer_object: Address;
  first_name: string;
  id: string;
  language: string;
  last_logged_on: number;
  last_name: string;
  manager_email: string;
  manager_name: string;
  manager_phone: string;
  name: string;
  password_last_set: number;
  phone_number: string;
  phone_type: string;
  profile_pic?: string;
  status: string;
  tabc: string;
  twitter_handle: string;
}

export interface TempUser {
  sub: string;
  email: string;
  password: string;
}

export interface IUser {
  sub: string;
  name: string;
  language: string;
  email: string;
  exp: number;
  iat: number;
  profile_pic?: string;
  is_manager?: boolean;
  employer_name?: string;
}

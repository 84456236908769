import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './modules/layout/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './modules/auth/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AuthGuard } from './core/service/auth-guard.service';
import { AuthService } from './core/service/auth.service';
import { CommonModule } from '@angular/common';
import { NgReduxModule, NgRedux } from '@angular-redux/store';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { rootReducer } from './app.root-reducer';
import { compose, applyMiddleware, Store, createStore } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import { CookieService } from 'ngx-cookie-service';
import { IAuthState } from './modules/auth/redux/auth.state';
import { ApiService } from './core/service/api.service';
import { SettingsService } from './core/service/settings.service';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ReportsActions } from './modules/layout/redux/reports.actions';
import { WebsocketService } from './shared/service/websocket.service';
import { DailyComponent } from './modules/reports/daily/daily.component';
import { AuthActions } from './modules/auth/redux/auth.actions';
import { OktaService } from './shared/service/okta-service';

const MATERIAL_COMPONENTS = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSortModule,
  MatTableModule,
  MatToolbarModule
];

export interface IAppState {
  readonly auth: IAuthState;
}

const persistConfig = {
  keyPrefix: 'fs-'
};

// @ts-ignore
export const store: Store<IAppState> = createStore(
  rootReducer,
  compose(
    // @ts-ignore
    applyMiddleware<ThunkDispatch<IAppState, void, Action>>(thunk, createLogger()),
    autoRehydrate()
  )
);

@Injectable()
export class AppConfigService {
  public load() {
    return new Promise<void>((resolve) => {
      persistStore(store, persistConfig, () => {
        resolve();
      });
    });
  }
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgReduxModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    CommonModule,
    GooglePlaceModule,
    ...MATERIAL_COMPONENTS
  ],
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, DailyComponent],
  providers: [
    AppConfigService,
    CookieService,
    WebsocketService,
    OktaService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService) => () => config.load(),
      deps: [AppConfigService],
      multi: true
    },
    AuthGuard,
    AuthService,
    ApiService,
    SettingsService,
    ReportsActions,
    AuthActions
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>) {
    ngRedux.provideStore(store);
  }
}

import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';

import { ChapterModel, PageModel, QuestionModel } from '../../shared/models';
import { ApiResponse } from '../../shared/models/api-response.model';
import { UserModel } from '../../shared/models/user.model';
import { API } from 'aws-amplify';
import { Page } from '../../modules/courses/redux/courses.state';
import { OktaService } from '../../shared/service/okta-service';

@Injectable()
export class ApiService {
  httpOptions: any;
  apiUrl: string;
  apiName = 'fs-api';

  constructor(private http: HttpClient) {}

  // Generic

  /**
   * @deprecated
   * */
  requestDataObservable(url: string): Observable<any> {
    const fullUrl: string = this.apiUrl + url;
    return this.http.get<QuestionModel>(fullUrl, this.httpOptions);
  }

  // AWS Amplify
  getUrl(url: string, params?: object) {
    console.log('getUrl');
    const myInit = {
      queryStringParameters: params
    };
    return API.get(this.apiName, url, myInit);
  }

  postUrl(url: string, data: any, params?: object) {
    console.log('postUrl');
    const myInit = {
      queryStringParameters: params,
      body: data
    };
    return API.post(this.apiName, url, myInit);
  }

  putUrl(url: string, data: any, params?: object) {
    console.log('putUrl');
    const myInit = {
      queryStringParameters: params,
      body: data
    };
    return API.put(this.apiName, url, myInit);
  }
}

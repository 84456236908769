<nav class="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
  <div class="container-fluid">
    <!-- Brand -->
    <a
      class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
      routerLinkActive="active"
      [routerLink]="['/dashboard']"
      >{{ getTitle() }}</a
    >
    <div
      class="h1 ml-4 mb-0 text-white text-uppercase d-none d-lg-inline-block badge badge-warning big"
    >
      {{ targetSite$ | async }}
    </div>
    <!-- Form -->
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
      [formGroup]="form"
      (ngSubmit)="sendSearch()"
    >
      <div class="form-group mb-0" [ngClass]="{ focused: focus === true }">
        <div class="input-group input-group-alternative">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input
            class="form-control text-white-50"
            formControlName="search"
            placeholder="Search"
            type="text"
            (focus)="focus = true"
            (blur)="focus = false"
          />
        </div>
      </div>
    </form>
    <!-- User -->
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img
                alt="Image placeholder"
                src="https://avatars.dicebear.com/api/male/ad614014-c8d0-4abf{{
                  (userName$ | async).toString().toLowerCase().replace(' ', '.')
                }}@a.svg"
              />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{ userName$ | async }}</span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome {{ userName$ | async }}!</h6>
          </div>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-settings-gear-65"></i>
            <span>Settings</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-calendar-grid-58"></i>
            <span>Activity</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/user-profile']" class="dropdown-item">
            <i class="ni ni-support-16"></i>
            <span>Support</span>
          </a>
          <div class="dropdown-divider"></div>
          <a [routerLink]="['/logout']" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>

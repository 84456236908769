import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class SettingsService {
  env_var: string;
  apiUrl: string;
  apiHeaderValue: string;

  constructor() {
    this.env_var = environment.production ? 'prod' : 'acc';
    this.apiUrl = environment.amplifyprod.API.endpoints[0].endpoint;
    this.apiHeaderValue = localStorage.getItem('token');
  }
}

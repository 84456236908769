import { combineReducers } from 'redux';
import { composeReducers, defaultFormReducer } from '@angular-redux/form';
import authReducer from './modules/auth/redux/auth.reducer';
import coursesReducer from './modules/courses/redux/courses.reducer';
import accountsReducer from './modules/accounts/redux/accounts.reducer';
import reportsReducer from './modules/layout/redux/reports.reducer';
import utilitiesReducer from './modules/utilities/redux/utilities.reducer';

export const appReducer = composeReducers(
  defaultFormReducer(),
  combineReducers({
    auth: authReducer,
    courses: coursesReducer,
    accounts: accountsReducer,
    reports: reportsReducer,
    utilities: utilitiesReducer
  })
);

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
    document.cookie = 'jwt' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  return appReducer(state, action);
};

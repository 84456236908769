const ReportTypes = {
  FAILURE: 'FAILURE',
  STATS_SET: 'STATS_SET',
  STATS_CLICK_SET: 'STATS_CLICK_SET',
  STATS_PURCHASES_MONTHLY_SET: 'STATS_PURCHASES_MONTHLY_SET',
  STATS_PURCHASES_DAILY_SET: 'STATS_PURCHASES_DAILY_SET',

  STATS_REVENUE_MONTHLY_SET: 'STATS_REVENUE_MONTHLY_SET',
  STATS_REVENUE_DAILY_SET: 'STATS_REVENUE_DAILY_SET',

  STATS_LOGINS_MONTHLY_SET: 'STATS_LOGINS_MONTHLY_SET',
  STATS_LOGINS_DAILY_SET: 'STATS_LOGINS_DAILY_SET',

  STATS_REGISTRATIONS_MONTHLY_SET: 'STATS_REGISTRATIONS_MONTHLY_SET',
  STATS_REGISTRATIONS_DAILY_SET: 'STATS_REGISTRATIONS_DAILY_SET',

  STATS_CURRENT_USER_UPDATE: 'STATS_CURRENT_USER_UPDATE',
  STATS_CURRENT_USERS_DAILY_SET: 'STATS_CURRENT-USERS_DAILY_SET',
  RESET_DATA: 'STATS_RESET_DATA'
};

export default ReportTypes;

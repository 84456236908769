import { INITIAL_STATE } from './reports.state';
import ReportTypes from './reports.types';
import { buildChartJs, upsertActiveUsers } from './reports.utils';

const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportTypes.FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case ReportTypes.RESET_DATA:
      return {
        ...state,
        currentUsers: undefined
      };
    case ReportTypes.STATS_CURRENT_USER_UPDATE:
      return {
        ...state,
        currentUsers: upsertActiveUsers(state.currentUsers, action.payload)
      };
    case ReportTypes.STATS_PURCHASES_MONTHLY_SET:
      return {
        ...state,
        purchases: {
          ...state.purchases,
          monthly: action.payload.data,
          monthlyChart: buildChartJs(action.payload.type, 'monthly', action.payload.data, 6)
        }
      };
    case ReportTypes.STATS_PURCHASES_DAILY_SET:
      return {
        ...state,
        purchases: {
          ...state.purchases,
          daily: action.payload.data,
          dailyChart: buildChartJs(action.payload.type, 'daily', action.payload.data)
        }
      };
    case ReportTypes.STATS_REVENUE_MONTHLY_SET:
      return {
        ...state,
        revenue: {
          ...state.revenue,
          monthly: action.payload.data,
          monthlyChart: buildChartJs(action.payload.type, 'monthly', action.payload.data)
        }
      };
    case ReportTypes.STATS_REVENUE_DAILY_SET:
      return {
        ...state,
        revenue: {
          ...state.revenue,
          daily: action.payload.data,
          dailyChart: buildChartJs(action.payload.type, 'daily', action.payload.data)
        }
      };
    case ReportTypes.STATS_LOGINS_MONTHLY_SET:
      return {
        ...state,
        logins: {
          ...state.logins,
          monthly: action.payload.data,
          monthlyChart: buildChartJs(action.payload.type, 'monthly', action.payload.data)
        }
      };
    case ReportTypes.STATS_LOGINS_DAILY_SET:
      return {
        ...state,
        logins: {
          ...state.logins,
          daily: action.payload.data,
          dailyChart: buildChartJs(action.payload.type, 'daily', action.payload.data)
        }
      };
    case ReportTypes.STATS_REGISTRATIONS_MONTHLY_SET:
      return {
        ...state,
        registrations: {
          ...state.registrations,
          monthly: action.payload.data,
          monthlyChart: buildChartJs(action.payload.type, 'monthly', action.payload.data)
        }
      };
    case ReportTypes.STATS_REGISTRATIONS_DAILY_SET:
      return {
        ...state,
        registrations: {
          ...state.registrations,
          daily: action.payload.data,
          dailyChart: buildChartJs(action.payload.type, 'daily', action.payload.data)
        }
      };
    case ReportTypes.STATS_CLICK_SET:
      return {
        ...state,
        showChart1: action.payload
      };
    case ReportTypes.STATS_CURRENT_USERS_DAILY_SET:
      return {
        ...state,
        currentUsers: action.payload.data
      };

    default:
      return state;
  }
};

export default reportsReducer;

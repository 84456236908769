import { Component, OnInit } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { NgRedux, select } from '@angular-redux/store';
import {
  selectEmail,
  selectIsLoggedIn,
  selectTargetSite,
  selectUserName
} from '../../modules/auth/redux/auth.selectors';
import { Observable } from 'rxjs/Observable';
import { AccountsActions } from '../../modules/accounts/redux/accounts.actions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnyAction } from 'redux';
import { IAppState } from '../../app.module';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @select(selectIsLoggedIn) readonly loggedIn$: Observable<boolean>;
  @select(selectUserName) readonly userName$: Observable<string>;
  @select(selectEmail) readonly email$: Observable<string>;
  @select(selectTargetSite) readonly targetSite$: Observable<string>;

  public focus;
  public listTitles: any[];
  public location: Location;
  public form: FormGroup;

  constructor(
    location: Location,
    public ngRedux: NgRedux<IAppState>,
    private accountsActions: AccountsActions,
    private fb: FormBuilder
  ) {
    this.location = location;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter((listTitle) => listTitle);
    this.form = this.fb.group({
      search: ''
    });
  }

  getTitle() {
    let titleE = this.location.prepareExternalUrl(this.location.path());
    if (titleE.charAt(0) === '#') {
      titleE = titleE.slice(1);
    }

    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titleE) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  sendSearch() {
    console.log('Sending search!');
    console.log(this.form.value.search);
    console.log(this.location.path());
    this.ngRedux.dispatch(
      <AnyAction>(
        (<unknown>this.accountsActions.runSearchAsync(this.form.value.search, this.location.path()))
      )
    );
  }
}

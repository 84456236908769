import { INITIAL_STATE } from './utilities.state';
import UtilityTypes from './utilities.types';

const utilitiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UtilityTypes.SET_DOTS:
      return {
        ...state,
        [`${action.payload.action}DotsStart`]: action.payload.value,
        [`${action.payload.action}Dots`]: 0
      };
    case UtilityTypes.INCREMENT_DOT:
      return {
        ...state,
        [`${action.payload.action}DotsStart`]: state[`${action.payload.action}DotsStart`] - 1,
        [`${action.payload.action}Dots`]: state[`${action.payload.action}Dots`] + 1
      };

    case UtilityTypes.EXPORT_ADD:
      return {
        ...state,
        exporting: [...state.exporting, action.payload]
      };
    case UtilityTypes.BACKUP_STATUS:
      return {
        ...state,
        backupStatus: action.payload
      };

    case UtilityTypes.IMPORT_ADD:
      return {
        ...state,
        importing: [...state.importing, action.payload]
      };

    default:
      return state;
  }
};

export default utilitiesReducer;

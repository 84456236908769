export interface IUtilitiesState {
  backupDots: number;
  backupDotsStart: number;
  restoreDots: number;
  restoreDotsStart: number;
  error: string;
  backupStatus: { id: string; exporting: boolean; importing: boolean }[];
  exporting: string[];
  importing: string[];
}

export const INITIAL_STATE: IUtilitiesState = {
  backupDotsStart: 0,
  backupDots: 0,
  restoreDotsStart: 0,
  restoreDots: 0,
  error: undefined,
  backupStatus: [],
  exporting: [],
  importing: []
};
export interface IUrlType {
  [K: string]: IUrlData;
}

export interface IUrlData {
  url: string;
  action?: string;
  dots: boolean;
  env?: string;
  tables?: string[];
}

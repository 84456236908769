let tokens;
let accessToken;
let objTokens;

export const base = {
  production: true,
  env: 'prod',
  region: 'us-east-2',

  amplify: {},
  amplifydev: {
    API: {
      endpoints: [
        {
          name: 'fs-api',
          endpoint: 'https://localhost:4999/webbi',
          custom_header: async () => {
            try {
              tokens = localStorage.getItem('okta-token-storage');
              if (tokens) {
                objTokens = JSON.parse(tokens);
                accessToken = objTokens?.accessToken?.accessToken;
                return { Authorization: accessToken };
              }
              return {};
            } catch (e) {
              console.error(e);
              return {};
            }
          }
        },
        {
          name: 'fs-webbie',
          endpoint: 'https://api.fullschedule.com/n7angu9wrd',
          custom_header: async () => {
            try {
              tokens = localStorage.getItem('okta-token-storage');
              if (tokens) {
                objTokens = JSON.parse(tokens);
                accessToken = objTokens?.accessToken?.accessToken;
                return { Authorization: accessToken };
              }
              return {};
            } catch (e) {
              console.error(e);
              return {};
            }
          }
        },
        {
          name: 'sockets',
          endpoint: 'wss://7zxl27t0q7.execute-api.us-east-2.amazonaws.com/dev' // ws://localhost:3001
        },
        {
          name: 'Assets',
          endpoint: 'https://assets.fullschedule.com'
        }
      ]
    }
  },
  amplifyprod: {
    API: {
      endpoints: [
        {
          name: 'fs-api',
          endpoint: 'https://api.fullschedule.com/w7angu9wrh/webbi',
          custom_header: async () => {
            try {
              tokens = localStorage.getItem('okta-token-storage');
              if (tokens) {
                objTokens = JSON.parse(tokens);
                accessToken = objTokens?.accessToken?.accessToken;
                return { Authorization: accessToken };
              }
              return {};
            } catch (e) {
              console.error(e);
              return {};
            }
          }
        },
        {
          name: 'fs-webbie',
          endpoint: 'https://api.fullschedule.com/n7angu9wrh',
          custom_header: async () => {
            try {
              tokens = localStorage.getItem('okta-token-storage');
              if (tokens) {
                objTokens = JSON.parse(tokens);
                accessToken = objTokens?.accessToken?.accessToken;
                return { Authorization: accessToken };
              }
              return {};
            } catch (e) {
              console.error(e);
              return {};
            }
          }
        },
        {
          name: 'sockets',
          endpoint: 'wss://246zbtwb5i.execute-api.us-east-2.amazonaws.com/prod'
        },
        {
          name: 'Assets',
          endpoint: 'https://assets.fullschedule.com'
        }
      ]
    }
  }
};

export interface IChartStruct {
  labels: string[];
  datasets: { label: string; data: number[] }[];
}

export interface IStat {
  date: string;
  qty: number;
  pct_change: number;
  pct_changeWoW: number;
}

export interface IStatStructure {
  monthly: IStat[];
  monthlyChart: IChartStruct;
  daily: IStat[];
  dailyChart: IChartStruct;
}

export interface IReportState {
  currentUsers: any;
  purchases: IStatStructure;
  revenue: IStatStructure;
  registrations: IStatStructure;
  logins: IStatStructure;
  redeem: IStatStructure;
  showChart1: number;
  error: string;
}

export const INITIAL_STATE: IReportState = {
  currentUsers: undefined,
  purchases: {
    monthly: undefined,
    monthlyChart: undefined,
    daily: undefined,
    dailyChart: undefined
  },
  revenue: {
    monthly: undefined,
    monthlyChart: undefined,
    daily: undefined,
    dailyChart: undefined
  },
  registrations: {
    monthly: undefined,
    monthlyChart: undefined,
    daily: undefined,
    dailyChart: undefined
  },
  redeem: {
    monthly: undefined,
    monthlyChart: undefined,
    daily: undefined,
    dailyChart: undefined
  },
  logins: {
    monthly: undefined,
    monthlyChart: undefined,
    daily: undefined,
    dailyChart: undefined
  },
  showChart1: 0,
  error: undefined
};

import { base } from './environment.base';

let tokens: string;
let accessToken: any;
let objTokens: { accessToken: { accessToken: any } };

export const environment = {
  ...base,
  amplifydev: {
    API: {
      endpoints: [
        {
          name: 'fs-api',
          endpoint: 'https://api.fullschedule.com/fsapiwebbidev/webbi',
          custom_header: async () => {
            try {
              tokens = localStorage.getItem('okta-token-storage');
              if (tokens) {
                objTokens = JSON.parse(tokens);
                accessToken = objTokens?.accessToken?.accessToken;
                return { Authorization: accessToken };
              }
              return {};
            } catch (e) {
              console.error(e);
              return {};
            }
          }
        },

        {
          name: 'fs-webbie',
          endpoint: 'https://api.fullschedule.com/fsapiwebbiedev',
          custom_header: async () => {
            try {
              tokens = localStorage.getItem('okta-token-storage');
              if (tokens) {
                objTokens = JSON.parse(tokens);
                accessToken = objTokens?.accessToken?.accessToken;
                return { Authorization: accessToken };
              }
              return {};
            } catch (e) {
              console.error(e);
              return {};
            }
          }
        },
        {
          name: 'Assets',
          endpoint: 'https://assets.fullschedule.com'
        },
        {
          name: 'sockets',
          endpoint: 'wss://4gc2qhrfre.execute-api.us-west-2.amazonaws.com/dev' // ws://localhost:3001
        }
      ]
    }
  },
  amplifyprod: {
    API: {
      endpoints: [
        {
          name: 'fs-api',
          endpoint: 'https://api.fullschedule.com/w7angu9wrh/webbi',
          custom_header: async () => {
            try {
              tokens = localStorage.getItem('okta-token-storage');
              if (tokens) {
                objTokens = JSON.parse(tokens);
                accessToken = objTokens?.accessToken?.accessToken;
                return { Authorization: accessToken };
              }
              return {};
            } catch (e) {
              console.error(e);
              return {};
            }
          }
        },
        {
          name: 'fs-webbie',
          endpoint: 'https://api.fullschedule.com/fsapiwebbie',
          custom_header: async () => {
            try {
              tokens = localStorage.getItem('okta-token-storage');
              if (tokens) {
                objTokens = JSON.parse(tokens);
                accessToken = objTokens?.accessToken?.accessToken;
                return { Authorization: accessToken };
              }
              return {};
            } catch (e) {
              console.error(e);
              return {};
            }
          }
        },
        {
          name: 'webbi-courses',
          endpoint: 'https://api.fullschedule.com/w-courses',
          custom_header: async () => {
            try {
              tokens = localStorage.getItem('okta-token-storage');
              if (tokens) {
                objTokens = JSON.parse(tokens);
                accessToken = objTokens?.accessToken?.accessToken;
                return { Authorization: accessToken };
              }
              return {};
            } catch (e) {
              console.error(e);
              return {};
            }
          }
        },
        {
          name: 'Assets',
          endpoint: 'https://assets.fullschedule.com'
        },
        {
          name: 'sockets',
          endpoint: 'wss://6mf5nhbpz9.execute-api.us-east-2.amazonaws.com/prod'
        }
      ]
    }
  }
};

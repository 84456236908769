const AuthTypes = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  CHANGE_TARGET: 'CHANGE_TARGET',
  CHECK_JWT: 'CHECK_JWT',
  SET_EMPLOYER: 'SET_EMPLOYER',
  SET_TARGET_SITE: 'SET_TARGET_SITE',
  SET_RETURN_URL: 'SET_RETURN_URL',
  SET_AUTH_CLIENT: 'SET_AUTH_CLIENT',
  SET_USER: 'SET_USER',
  PROFILE_START_SAVE: 'PROFILE_START_SAVE',
  PROFILE_SAVE_SUCCESS: 'PROFILE_SAVE_SUCCESS',
  PROFILE_SAVE_FAILURE: 'PROFILE_SAVE_FAILURE',
  SIGNUP_NEXT_STEP: 'SIGNUP_NEXT_STEP'
};

export default AuthTypes;

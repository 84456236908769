import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import Amplify, { Auth, API } from 'aws-amplify';
import { environment } from '../../../environments/environment';
import { fromPromise } from 'rxjs/internal-compatibility';

interface IUserAttributes {
  name: string;
  email: string;
  email_verified: string;
}

interface TokenDto {
  foo: string;
  exp: number;
  iat: number;
}

@Injectable()
export class AuthService {
  loggedIn: BehaviorSubject<boolean>;

  constructor(private router: Router) {
    Amplify.configure(environment.amplifydev);
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  /** signup */
  signUp(email, password): Observable<any> {
    return fromPromise(Auth.signUp(email.toLowerCase(), password));
  }

  /** confirm code */
  confirmSignUp(email, code): Observable<any> {
    return fromPromise(Auth.confirmSignUp(email.toLowerCase(), code));
  }
  /** forgot password */
  forgotPassword(email): Observable<any> {
    return fromPromise(Auth.forgotPassword(email.toLowerCase()));
  } /** forgot password */

  confirmNewPassword(email, code, pw): Observable<any> {
    return fromPromise(Auth.completeNewPassword(email, pw, code));
  }

  /** signin */
  signIn(email: string, password: string): Observable<any> {
    return fromPromise(Auth.signIn(email.toLowerCase(), password)).pipe(
      tap(() => this.loggedIn.next(true))
    );
  }

  /** get authenticated state */
  isAuthenticated(): Observable<boolean> {
    return fromPromise(Auth.currentSession()).pipe(
      map((result) => {
        this.loggedIn.next(true);
        return true;
      }),
      catchError((error) => {
        this.loggedIn.next(false);
        return of(false);
      })
    );
  }

  /** sign out */
  signOut() {
    fromPromise(Auth.signOut()).subscribe(
      (result) => {
        this.loggedIn.next(false);
        this.router.navigate(['/']);
      },
      (error) => console.log(error)
    );
  }

  /** Helper functions **/
  async getEmail(): Promise<string> {
    const user = await this.getCurrentUser();
    return user.username;
  }

  getCurrentUser() {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      return API.post('api', 'authorize', { body: { token: jwt } })
        .then((e) => {
          const attributes: IUserAttributes = {
            name: e.name,
            email: e.email,
            email_verified: e.email_verified
          };
          return {
            username: e['cognito:username'],
            attributes
          };
        })
        .catch((e) => Auth.currentAuthenticatedUser());
    } else {
      return Auth.currentAuthenticatedUser();
    }
  }
}

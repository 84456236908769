import AuthTypes from './auth.types';
import { INITIAL_STATE } from './auth.state';

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.LOGIN:
      return {
        ...state,
        user: action.payload,
        loggedIn: true
      };
    case AuthTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        user: undefined
      };
    case AuthTypes.CHANGE_LANGUAGE:
      if (state.loggedIn) {
      }
      return {
        ...state,
        language: action.payload,
        user: state.user
      };
    case AuthTypes.SET_EMPLOYER:
      return {
        ...state,
        language: action.payload,
        user: {
          ...state.user,
          employer_object: action.payload,
          employer: action.payload.name
        }
      };
    case AuthTypes.SET_AUTH_CLIENT:
      return {
        ...state,
        oktaClient: action.payload
      };
    case AuthTypes.SET_USER:
      return {
        ...state,
        user: action.payload
      };
    case AuthTypes.SET_TARGET_SITE:
      return {
        ...state,
        targetSite: action.payload
      };
    case AuthTypes.SIGNUP_NEXT_STEP:
      return {
        ...state,
        signUpStep: action.payload
      };
    case AuthTypes.PROFILE_SAVE_SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    case AuthTypes.CHECK_JWT:
      // We only check this for a logged in user
      if (state.loggedIn) {
        const user = state.user;
        // state.user.language = action.payload;
        return {
          ...state,
          language: action.payload,
          user
        };
      } else {
        return {
          ...state
        };
      }
    default:
      return state;
  }
};

export default authReducer;

import { AccessToken, OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { Injectable } from '@angular/core';

@Injectable()
export class OktaService {
  oktaAuth: OktaAuth;

  constructor() {
    if (!this.oktaAuth) {
      const config: OktaAuthOptions = {
        issuer: 'https://dev-20497970.okta.com/oauth2/default',
        clientId: '0oa6k6m4wnwyIK7bA5d7',
        redirectUri: `${window.location.origin}/login`,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        tokenManager: {
          autoRenew: true
        }
      };
      this.oktaAuth = new OktaAuth(config);
      this.oktaAuth.start();
    }
  }

  getAuthClient(): OktaAuth {
    return this.oktaAuth;
  }

  async getAccessToken(): Promise<AccessToken> {
    return (await this.getAuthClient().tokenManager.get('accessToken')) as AccessToken;
  }
}

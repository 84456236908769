import ReportTypes from './reports.types';
import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';

@Injectable()
export class ReportsActions {
  apiName = 'fs-api';

  constructor() {
    // Amplify.configure(environment.amplifydev);
  }

  statsSave = (type, interval, data) => {
    return {
      type: `STATS_${type.toUpperCase()}_${interval.toUpperCase()}_SET`,
      payload: { type, data }
    };
  };

  activeUserUpdate = (data) => {
    return {
      type: ReportTypes.STATS_CURRENT_USER_UPDATE,
      payload: data
    };
  };

  setClick = (value) => ({
    type: ReportTypes.STATS_CLICK_SET,
    payload: value
  });

  failure = (value) => ({
    type: ReportTypes.FAILURE,
    payload: value
  });

  resetData = () => ({
    type: ReportTypes.RESET_DATA
  });

  fetchCurrentUsersAsync = (item: string, interval: string, limit: number, apiName?: string) => {
    return (dispatch) => {
      API.get(apiName ? apiName : this.apiName, `/webbi/dashboard/current-users?type=${interval}&limit=${limit}`, {})
        .then((response) => {
          dispatch(this.statsSave(item, interval, response.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.failure(error.message));
        });
    };
  };

  fetchStatsAsync = (item: string, interval: string, limit: number, apiName?: string) => {
    return (dispatch) => {
      API.get(apiName ? apiName : this.apiName, `/dashboard/stats/${item}?type=${interval}&limit=${limit}`, {})
        .then((response) => {
          dispatch(this.statsSave(item, interval, response.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.failure(error.message));
        });
    };
  };

  processIncomingSocket = (data) => {
    return (dispatch) => {
      const message: any = JSON.parse(data.data);

      let arrMessage: any[];
      if (data.action !== 'multi') {
        arrMessage = [message];
      } else {
        arrMessage = <any[]>(<unknown>message);
      }

      arrMessage.forEach((objMessage: any) => {
        console.log(objMessage);
        switch (objMessage.action) {
          case 'active-modify':
            // dispatch and process
            dispatch(this.activeUserUpdate(objMessage.data));
            break;
          default:
          // nothing
        }
      });
    };
  };
}

export const CoursesTypes = {
  FAILURE: 'FAILURE',
  START: 'START',
  START_SAVE: 'START_SAVE',
  END_SAVE: 'END_SAVE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',

  CURRENT_COURSE: 'CURRENT_COURSE',

  CHAPTERS_LOAD_SUCCESS: 'CHAPTERS_LOAD_SUCCESS',

  COURSES_LOAD_SUCCESS: 'COURSES_LOAD_SUCCESS',
  COURSE_SAVE_SUCCESS: 'COURSE_SAVE_SUCCESS',
  COURSE_PUBLISH_SUCCESS: 'COURSE_PUBLISH_SUCCESS',

  QUESTIONS_LOAD_SUCCESS: 'QUESTIONS_LOAD_SUCCESS',
  QUESTIONS_SAVE_SUCCESS: 'QUESTIONS_SAVE_SUCCESS',
  QUESTIONS_PAGINATE: 'QUESTIONS_PAGINATE',
  QUESTIONS_UPDATE_SORT: 'QUESTIONS_UPDATE_SORT',
  QUESTIONS_SHOWING: 'QUESTIONS_SHOWING',
  QUESTIONS_START: 'QUESTIONS_START',
  QUESTION_EDIT: 'QUESTION_EDIT',

  PAGE_LOAD_SUCCESS: 'PAGE_LOAD_SUCCESS',
  PAGES_LOAD_SUCCESS: 'PAGES_LOAD_SUCCESS',
  PAGES_SAVE_SUCCESS: 'PAGES_SAVE_SUCCESS',
  PAGES_PAGINATE: 'PAGES_PAGINATE',
  PAGES_UPDATE_SORT: 'PAGES_UPDATE_SORT',
  PAGES_SHOWING: 'PAGES_SHOWING',
  PAGE_SET_EDIT_ID: 'PAGE_EDIT',
  PAGE_SET: 'PAGE_SET',

  RESET_DATA: 'COURSES_RESET_DATA',

  SECTIONS_LOAD_SUCCESS: 'SECTIONS_LOAD_SUCCESS',
  SET_TARGET_SITE: 'SET_TARGET_SITE'
};

export const AccountsTypes = {
  FAILURE: 'FAILURE',
  ERROR_CLEAR: 'ERROR_CLEAR',
  START: 'START',
  START_SAVE: 'START_SAVE',

  CURRENT_USER: 'CURRENT_USER',

  CHAPTERS_LOAD_SUCCESS: 'CHAPTERS_LOAD_SUCCESS',

  EMPLOYER_LOAD_SUCCESS: 'EMPLOYER_LOAD_SUCCESS',
  EMPLOYERS_LOAD_SUCCESS: 'EMPLOYERS_LOAD_SUCCESS',
  EMPLOYERS_SAVE_SUCCESS: 'EMPLOYERS_SAVE_SUCCESS',
  EMPLOYERS_PAGINATE: 'EMPLOYERS_PAGINATE',
  EMPLOYERS_UPDATE_SORT: 'EMPLOYERS_UPDATE_SORT',
  EMPLOYERS_SHOWING: 'EMPLOYERS_SHOWING',
  EMPLOYERS_START: 'EMPLOYERS_START',

  SUBSCRIBER_LOAD_SUCCESS: 'SUBSCRIBER_LOAD_SUCCESS',
  SUBSCRIBER_LOAD_CODES_SUCCESS: 'SUBSCRIBER_LOAD_CODES_SUCCESS',
  SUBSCRIBER_LOAD_PACKAGES_SUCCESS: 'SUBSCRIBER_LOAD_PACKAGES_SUCCESS',

  SUBSCRIBERS_LOAD_SUCCESS: 'SUBSCRIBERS_LOAD_SUCCESS',
  SUBSCRIBERS_SAVE_SUCCESS: 'SUBSCRIBERS_SAVE_SUCCESS',
  SUBSCRIBERS_PAGINATE: 'SUBSCRIBERS_PAGINATE',
  SUBSCRIBERS_UPDATE_SORT: 'SUBSCRIBERS_UPDATE_SORT',
  SUBSCRIBERS_SHOWING: 'SUBSCRIBERS_SHOWING',

  APIS_LOAD_SUCCESS: 'APIS_LOAD_SUCCESS',
  APIS_SAVE_SUCCESS: 'APIS_SAVE_SUCCESS',
  APIS_PAGINATE: 'APIS_PAGINATE',
  APIS_UPDATE_SORT: 'APIS_UPDATE_SORT',
  APIS_SHOWING: 'APIS_SHOWING',

  RESET_DATA: 'ACCOUNTS_RESET_DATA',
  SET_COUNT: 'SET_COUNT',

  SET_TARGET_SITE: 'SET_TARGET_SITE',

  USER_LOAD_SUCCESS: 'USER_LOAD_SUCCESS',
  USER_LOAD_ORDER_SUCCESS: 'USER_LOAD_ORDER_SUCCESS',
  USER_LOAD_TOKEN_SUCCESS: 'USER_LOAD_TOKEN_SUCCESS',
  USER_SAVE_SUCCESS: 'USER_SAVE_SUCCESS',

  USERS_LOAD_SUCCESS: 'USERS_LOAD_SUCCESS',
  USERS_SAVE_SUCCESS: 'USERS_SAVE_SUCCESS',
  USERS_PAGINATE: 'USERS_PAGINATE',
  USERS_UPDATE_SORT: 'USERS_UPDATE_SORT',
  USERS_LOADING_START: 'USERS_LOADING_START',
  USERS_SHOWING: 'USERS_SHOWING',
  USER_EDIT: 'USER_EDIT',

  WIPE_START: 'WIPE_START',
  WIPE_SUCCESS: 'WIPE_SUCCESS'
};

import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { DynamoEmployer } from '../../../shared/models/dynamo-employer.model';

export const INITIAL_STATE: IAccountsState = {
  chapters: undefined,

  error: undefined,
  loading: false,

  searchTerm: undefined,

  subscriber: undefined,
  subscriberTokens: undefined,
  subscriberPackages: undefined,

  subscribers: undefined,
  subscribersCount: undefined,
  subscribersKeys: {},
  subscribersParameters: {
    search: undefined,
    showing: 'all',
    sortField: 'name',
    sortDirection: 'asc',
    start: 0,
    limit: 10,
    next: undefined,
    index: 0
  },

  apis: undefined,
  apisCount: undefined,
  apisKeys: {},
  apisParameters: {
    search: undefined,
    showing: 'all',
    sortField: 'name',
    sortDirection: 'asc',
    start: 0,
    limit: 10,
    next: undefined,
    index: 0
  },
  publishing: false,

  employer: undefined,

  employers: undefined,
  employersCount: undefined,
  employersKeys: {},
  employersParameters: {
    search: undefined,
    showing: 'all',
    sortField: 'name',
    sortDirection: 'asc',
    start: 0,
    limit: 10,
    next: undefined,
    index: 0
  },
  saving: false,
  sections: undefined,

  user: undefined,
  userOrders: undefined,
  userTokens: undefined,

  users: undefined,
  usersCount: undefined,
  usersKeys: {},
  usersLoading: false,
  usersParameters: {
    search: undefined,
    showing: 'all',
    sortField: 'created_at',
    sortDirection: 'desc',
    start: 0,
    limit: 10,
    next: undefined,
    index: 0
  },
  wiping: undefined
};

export interface IAccountsState {
  chapters: Chapter[];

  error: string;
  loading: boolean;

  publishing: boolean;

  employer: DynamoEmployer;

  employers: DynamoEmployer[];
  employersCount: number;
  employersKeys: { [id: string]: any };
  employersParameters: IParameters;

  saving: boolean;
  sections: Section[];

  searchTerm: string;

  subscriber: Subscriber;
  subscriberTokens: any;
  subscriberPackages: any;

  subscribers: Subscriber[];
  subscribersCount: number;
  subscribersKeys: { [id: string]: any };
  subscribersParameters: IParameters;

  apis: ApiKey[];
  apisCount: number;
  apisKeys: { [id: string]: any };
  apisParameters: IParameters;

  user: User;
  userTokens: any;
  userOrders: any;

  users: User[];
  usersCount: number;
  usersKeys: { [id: string]: any };
  usersLoading: boolean;
  usersParameters: IParameters;

  wiping: string;
}

export interface IParameters {
  search: string;
  showing: string;
  sortField: string;
  sortDirection: string;
  start: number;
  limit: number;
  next?: any;
  index?: number;
}

export interface User {
  username: string;
  sub: string;
  email: string;
  created_at: string;
  last_logged_on: string;
  origin: string;

  browser?: string;
  created_at_h?: string;
  email_verified?: boolean;
  email_bounced?: boolean;
  employer?: string;
  employer_object?: Address;
  id?: string;
  id_google?: string;
  language?: string;
  location?: string;
  name?: string;
  name_verified?: boolean;
  password_hash?: string;
  password_last_set?: boolean;
  phone_number?: string;
  phone_type?: string;
  search_amalgamation?: string;
  status?: string;
  stripe_customer?: object;
  is_manager?: boolean;
  employer_id?: string;
  subscriber_id?: string;
  to_expire?: number;

  analytics_clv?: number;
}

export interface Chapter {
  en: string;
  order: number;
  section: Section;
  id: string;
  user: string;
  es: string;
}

export interface Section {
  user: string;
  en: string;
  id: string;
  es: string;
  order: number;
}

export interface Package {
  id: string;
  course: string;
  package_qty_remaining?: number;
  package_auto_fill: boolean;
  package_qty: number;
  code_type: string;
  code: string;
}

export interface ApiKey {
  id: string;
  apiKey: string; // Subscriber Business Name
  name: string; // Subscriber Business Name
}

export interface Subscriber {
  id: string;
  certificate_email: string | undefined;
  created_at: number | undefined;
  created_by: string;
  created_at_h: string | undefined;
  code: string;
  code_type: string; // 'individual' or 'shared' or 'free'
  data_type: string; // account, employees_active, employees_inactive, invoices-2019-XX-XX
  employers: DynamoEmployer[];
  state: string;
  employer_zip: string;
  name: string; // Subscriber Business Name
  notify_on_usage: number;
  manager_email: string;
  managers: Object[];
  manager_id: string; // Primary Manager Id
  manager_name: string;
  location: string | undefined;
  package: string;
  package_auto_fill: boolean;
  package_qty: number;
  package_qty_remaining: number;
  phone_number: string | undefined;
  language: string | undefined;
  search_amalgamation: string;
  stripe_status: string;
  notes: string | undefined;
}

// DynamoEmployer should be priority?
export class Employer {
  id: string;
  created_at: number | undefined;
  code: string;
  code_type: string | undefined;
  employers?: Address[];
  employer_object?: Address;
  employer_phone: string;
  employer_address: string;
  employer_city: string;
  state: string;
  employer_zip: string;
  name: string;
  notify_on_usage: number;
  manager_email: string;
  manager_id: string;
  manager_name: string;
  location: string | undefined;
  package: string;
  package_auto_fill: boolean;
  package_qty: number;
  package_qty_remaining: number;
  phone_number: string | undefined;
  language: string | undefined;
  search_amalgamation: string;
  suggester: boolean;
  notes: string | undefined;
  reset_code: string | undefined;
  current_employees: Employee[];
}

export class Employee {
  id: string;
  first_name: string;
  last_name: string;
  certifications?: IUserCertificate[];
}

interface IUserCertificate {
  id: string;
  status: string;
  course: string;
  language: string;
  completed_at: number;
  token_type: string;
  record_locator: string;
  renewal_eligible_at: number;
  expires_at: number;
  test_score: number;
  test_time: number;
  score_results: object | null;
  code_used: boolean;
  certified_by?: string; // cent or other company
}

export class EmployerModel {
  employer: string;
  correct: string;
  incorrect_1: string;
  incorrect_2: string;
  incorrect_3: string;
  explanation: string;
}

import { DateTime } from 'luxon';
import { TitleCasePipe } from '@angular/common';
const titleCasePipe = new TitleCasePipe();

export const upsertActiveUsers = (collection: any[], newItem: any) => {
  const existingItem = collection.find((item) => item.pk === newItem.pk);

  if (existingItem) {
    return collection.map((item) => (item.pk === newItem.pk ? newItem : item));
  }

  return [...collection, newItem];
};

export const buildChartJs = (type: string, interval: string, data: any, limitTo = 0) => {
  const labels = [];
  const qty = [];

  let maxBarThickness;
  switch (type) {
    case 'revenue':
      maxBarThickness = 50;
      break;
    default:
      maxBarThickness = 8;
  }

  let loop = 0;
  data.forEach((item) => {
    if (loop >= limitTo) {
      switch (interval) {
        case 'monthly':
          labels.push(DateTime.fromISO(`${item.date}-01`).setZone('America/Chicago').toFormat('LLL'));
          break;
        case 'daily':
          labels.push(DateTime.fromISO(`${item.date}`).setZone('America/Chicago').toFormat('ccc d'));
          break;
        default:
          labels.push(item.date);
      }
      qty.push(Math.round(+item.qty));
    }
    loop++;
  });

  return {
    labels: labels,
    datasets: [
      {
        // pointBackgroundColor: 'green',
        pointRadius: 5,
        fill: false,
        // showLine: false, // <- set this
        maxBarThickness: maxBarThickness,
        label: titleCasePipe.transform(type),
        data: qty
      }
    ]
  };
};

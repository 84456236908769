import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '../../../core/service/api.service';

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss']
})
export class DailyComponent implements OnInit {
  public id;
  public htmlReport;
  public title;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.id = '2021-11-16';

    this.apiService.getUrl(`/reports/daily/${this.id}`).then((result) => {
      console.log(result.data);
      this.htmlReport = result.data.html;
      this.title = result.data.subject;
      this.loading = false;
    });
  }
}

import AuthTypes from './auth.types';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { Router } from '@angular/router';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { IAppState } from '../../../app.module';
import Amplify, { Auth } from 'aws-amplify';
import { environment } from '../../../../environments/environment';
import { AccessToken, IDToken, OktaAuth, UserClaims } from '@okta/okta-auth-js';
import * as jwtDecode from 'jwt-decode';

@Injectable()
export class AuthActions {
  constructor(private ngRedux: NgRedux<IAppState>, private router: Router) {
    Amplify.configure(environment.amplifydev);
  }

  loginSuccess = (user) => {
    return {
      type: AuthTypes.LOGIN,
      payload: user
    };
  };

  changeLanguage = (language) => ({
    type: AuthTypes.CHANGE_LANGUAGE,
    payload: language
  });

  setEmployer = (employer_object: Address) => ({
    type: AuthTypes.SET_EMPLOYER,
    payload: employer_object
  });

  setReturnUrl = (value: string) => {
    return {
      type: AuthTypes.SET_RETURN_URL,
      payload: value
    };
  };

  setTargetSite = (target: string) => {
    if (target === 'prod') {
      Amplify.configure(environment.amplifyprod);
    }
    if (target === 'dev') {
      Amplify.configure(environment.amplifydev);
    }
    return {
      type: AuthTypes.SET_TARGET_SITE,
      payload: target
    };
  };

  logout = () => ({
    type: AuthTypes.LOGOUT
  });

  setOktaAuthClient = (authClient: OktaAuth) => ({
    type: AuthTypes.SET_AUTH_CLIENT,
    payload: authClient
  });

  setUser = (value: UserClaims | undefined) => ({
    type: AuthTypes.SET_USER,
    payload: value
  });

  checkJwtRefreshAsync = (forceRenewal = false) => {
    return async (dispatch, state) => {
      let exp = 0,
        now = 0;
      const globalState = state();
      let authClient = globalState.auth.oktaClient;
      if (!authClient) {
        return;
      }

      let accessToken: AccessToken = (await authClient.tokenManager.get(
        'accessToken'
      )) as AccessToken;
      let idToken: IDToken = (await authClient.tokenManager.get('idToken')) as IDToken;

      if (!forceRenewal) {
        const objUser = jwtDecode(accessToken);
        exp = objUser.exp;
        now = Date.now().valueOf() / 1000;
      }
      if (forceRenewal || exp - 300 < now) {
        if (!forceRenewal) {
          console.log('expired token');
          dispatch(this.logout());
          this.router.navigate(['/'], { queryParams: { timeout: true } }).then();
          return;
        }
        if (forceRenewal) {
          console.log('forced refresh');
        }
        authClient.tokenManager.renew();
      }
    };
  };
}

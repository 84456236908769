import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import { selectTargetSite } from '../../modules/auth/redux/auth.selectors';
import { Observable } from 'rxjs/Observable';
import { IAppState } from '../../app.module';
import { AuthActions } from '../../modules/auth/redux/auth.actions';
import { CoursesActions } from '../../modules/courses/redux/courses.actions';
import { ReportsActions } from '../../modules/layout/redux/reports.actions';
import { AnyAction } from 'redux';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  children?: RouteInfo[];
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: '' },
  {
    path: '/reports',
    title: 'Reports',
    icon: 'ni-chart-bar-32 text-primary',
    class: '',
    children: [
      {
        path: '/reports/daily',
        title: 'Daily Report',
        icon: 'ni-calendar-grid-58 text-blue',
        class: ''
      },
      {
        path: '/reports/big',
        title: 'The Big Report',
        icon: 'ni-chart-pie-35 text-blue',
        class: ''
      }
    ]
  },
  {
    path: '/courses',
    title: 'Courses',
    icon: 'ni-tv-2 text-primary',
    class: '',
    children: [
      { path: '/courses', title: 'Course List', icon: 'ni-books text-blue', class: '' },
      {
        path: '/courses/support-pages/pages',
        title: 'Transactional Emails',
        icon: 'ni-email-83 text-blue',
        class: ''
      }
    ]
  },
  {
    path: '/accounts',
    title: 'Accounts',
    icon: 'ni-tv-2 text-primary',
    class: '',
    children: [
      { path: '/accounts/users', title: 'Users', icon: 'fa fa-users text-blue', class: '' },
      {
        path: '/accounts/subscribers',
        title: 'Subscribers',
        icon: 'fa fa-money text-blue',
        class: ''
      },
      {
        path: '/accounts/employers',
        title: 'Employers',
        icon: 'fa fa-google text-blue',
        class: ''
      },
      {
        path: '/accounts/api-keys',
        title: 'API Keys',
        icon: 'ni-sound-wave text-blue',
        class: ''
      }
    ]
  },
  { path: '/utilities', title: 'Site Utilities', icon: 'ni-settings-gear-65 text-blue', class: '' },
  { path: '/icons', title: 'Icons', icon: 'ni-planet text-blue', class: '' },
  { path: '/maps', title: 'Maps', icon: 'ni-pin-3 text-orange', class: '' },
  { path: '/user-profile', title: 'User profile', icon: 'ni-single-02 text-yellow', class: '' },
  { path: '/tables', title: 'Tables', icon: 'ni-bullet-list-67 text-red', class: '' },
  { path: '/login', title: 'Login', icon: 'ni-key-25 text-info', class: '' },
  { path: '/register', title: 'Register', icon: 'ni-circle-08 text-pink', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [AuthActions]
})
export class SidebarComponent implements OnInit {
  @select(selectTargetSite) readonly targetSite$: Observable<string>;

  public menuItems: any[];
  public isCollapsed = true;
  public isProd = false;
  public init = false;

  constructor(
    private router: Router,
    private ngRedux: NgRedux<IAppState>,
    private authActions: AuthActions,
    private courseActions: CoursesActions,
    private reportsActions: ReportsActions
  ) {}

  ngOnInit() {
    this.targetSite$.subscribe((target) => {
      this.isProd = target === 'prod';
      console.log(`ngInit target is ${target} ${this.isProd}`);
      if (!this.init) {
        this.init = true;
        this.ngRedux.dispatch(this.authActions.setTargetSite(this.isProd ? 'prod' : 'dev'));
      }
    });

    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  onChange(selection: any) {
    console.log(`OnChange ${selection}`);
    this.ngRedux.dispatch(this.authActions.setTargetSite(selection ? 'dev' : 'prod'));
    // this.ngRedux.dispatch(this.courseActions.setTargetSite(selection ? 'dev' : 'prod'));
    this.ngRedux.dispatch(this.reportsActions.resetData());
    this.ngRedux.dispatch(
      <AnyAction>(<unknown>this.reportsActions.fetchCurrentUsersAsync('current-users', 'daily', 500, 'fs-webbie'))
    );
    // this.ngRedux.dispatch(this.reportActions.());
    this.ngRedux.dispatch(this.courseActions.resetData());
  }
}

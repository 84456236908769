import { INITIAL_STATE } from './courses.state';
import { CoursesTypes } from './courses.types';
import {
  filterPagesByShowing,
  filterQuestionsByShowing,
  sortPages,
  sortQuestions,
  upsertCourse,
  upsertPage,
  upsertQuestion
} from './courses.utils';

const coursesReducer = (state = INITIAL_STATE, action) => {
  let sortedData;
  let parameters;
  switch (action.type) {
    case CoursesTypes.START:
      return {
        ...state,
        loading: true
      };
    case CoursesTypes.CLEAR_MESSAGE:
      return {
        ...state,
        message: undefined
      };
    case CoursesTypes.CHAPTERS_LOAD_SUCCESS:
      return {
        ...state,
        chapters: action.payload
      };
    case CoursesTypes.COURSES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload,
        error: undefined,
        message: undefined
      };
    case CoursesTypes.COURSE_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        saving: false,
        courses: upsertCourse(state.courses, action.payload)
      };
    case CoursesTypes.CURRENT_COURSE:
      return {
        ...state,
        currentCourse: action.payload
      };
    case CoursesTypes.FAILURE:
      return {
        ...state,
        loading: false,
        saving: false,
        error: action.payload,
        message: undefined
      };
    case CoursesTypes.PAGE_SET_EDIT_ID:
      return {
        ...state,
        currentPage: action.payload
      };
    case CoursesTypes.PAGE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        page: action.payload
      };
    case CoursesTypes.PAGES_LOAD_SUCCESS:
      sortedData = sortPages(action.payload, state.pagesParameters);
      return {
        ...state,
        loading: false,
        error: undefined,
        message: undefined,
        pages: sortedData,
        pagesFiltered: filterPagesByShowing(sortedData, state.pagesParameters)
      };
    case CoursesTypes.PAGES_PAGINATE:
      return {
        ...state,
        pagesParameters: {
          ...state.pagesParameters,
          start: action.payload.start,
          limit: action.payload.limit
        }
      };
    case CoursesTypes.PAGES_SAVE_SUCCESS:
      sortedData = sortPages(upsertPage(state.pages, action.payload), state.pagesParameters);
      return {
        ...state,
        saving: false,
        error: undefined,
        pages: sortedData,
        pagesFiltered: filterPagesByShowing(sortedData, state.pagesParameters)
      };
    case CoursesTypes.PAGES_UPDATE_SORT:
      parameters = state.pagesParameters;
      parameters.sortDirection = action.payload.sortDirection;
      parameters.sortField = action.payload.sortField;
      parameters.start = 0;

      sortedData = sortPages(state.pages, parameters);
      return {
        ...state,
        pages: sortedData,
        pagesFiltered: filterPagesByShowing(sortedData, parameters),
        pagesParameters: parameters
      };
    case CoursesTypes.COURSE_PUBLISH_SUCCESS:
      return {
        ...state,
        saving: false,
        message: action.payload,
        error: undefined
      };
    case CoursesTypes.QUESTION_EDIT:
      return {
        ...state,
        currentQuestion: action.payload
      };
    case CoursesTypes.QUESTIONS_LOAD_SUCCESS:
      sortedData = sortQuestions(action.payload, state.questionsParameters);
      return {
        ...state,
        error: undefined,
        questions: sortedData,
        questionsFiltered: filterQuestionsByShowing(sortedData, state.questionsParameters)
      };
    case CoursesTypes.QUESTIONS_PAGINATE:
      return {
        ...state,
        questionsParameters: {
          ...state.questionsParameters,
          start: action.payload.start,
          limit: action.payload.limit
        }
      };
    case CoursesTypes.QUESTIONS_UPDATE_SORT:
      parameters = state.questionsParameters;
      parameters.sortDirection = action.payload.sortDirection;
      parameters.sortField = action.payload.sortField;
      parameters.start = 0;

      sortedData = sortQuestions(state.questions, parameters);
      return {
        ...state,
        questions: sortedData,
        questionsFiltered: filterQuestionsByShowing(sortedData, parameters),
        questionsParameters: parameters
      };
    case CoursesTypes.QUESTIONS_SHOWING:
      parameters = state.questionsParameters;
      parameters.showing = action.payload;
      return {
        ...state,
        questionsFiltered: filterQuestionsByShowing(state.questions, parameters),
        questionsParameters: {
          ...state.questionsParameters,
          start: 0,
          showing: action.payload
        }
      };
    case CoursesTypes.START_SAVE:
      return {
        ...state,
        saving: true,
        error: undefined,
        message: undefined
      };
    case CoursesTypes.END_SAVE:
      return {
        ...state,
        saving: false,
        error: undefined,
        message: undefined
      };
    case CoursesTypes.QUESTIONS_SAVE_SUCCESS:
      sortedData = sortQuestions(
        upsertQuestion(state.questions, action.payload),
        state.questionsParameters
      );
      return {
        ...state,
        saving: false,
        error: undefined,
        questions: sortedData,
        questionsFiltered: filterQuestionsByShowing(sortedData, state.questionsParameters)
      };
    case CoursesTypes.QUESTIONS_START:
      return {
        ...state,
        error: undefined,
        questions: undefined,
        questionsFiltered: undefined
      };
    case CoursesTypes.RESET_DATA:
      return {
        ...state,
        error: undefined,
        sections: undefined,
        courses: undefined,
        questions: undefined,
        questionsFiltered: undefined,
        pages: undefined,
        pagesFiltered: undefined
      };
    case CoursesTypes.SECTIONS_LOAD_SUCCESS:
      return {
        ...state,
        error: undefined,
        sections: action.payload
      };
    case CoursesTypes.SET_TARGET_SITE:
      return {
        ...state,
        error: undefined
      };

    default:
      return state;
  }
};

export default coursesReducer;

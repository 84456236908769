import { Injectable } from '@angular/core';
import Amplify, { API } from 'aws-amplify';
import { environment } from '../../../../environments/environment';
import { AccountsTypes } from './accounts.types';
import { IParameters, IAccountsState } from './accounts.state';
import { StringHelper } from '../../../shared/string-helper';
import { AnyAction } from 'redux';

@Injectable()
export class AccountsActions {
  apiName = 'fs-api';

  start = (data) => ({
    type: AccountsTypes.START,
    payload: data
  });

  chaptersSuccess = (data) => ({
    type: AccountsTypes.CHAPTERS_LOAD_SUCCESS,
    payload: data
  });

  clearError = () => ({
    type: AccountsTypes.ERROR_CLEAR
  });

  usersSuccess = (data, key, parameters) => ({
    type: AccountsTypes.USERS_LOAD_SUCCESS,
    payload: { data, key, parameters }
  });

  currentUser = (data) => ({
    type: AccountsTypes.CURRENT_USER,
    payload: data
  });

  failure = (data) => ({
    type: AccountsTypes.FAILURE,
    payload: data
  });

  apiSuccess = (data, key, parameters) => ({
    type: AccountsTypes.APIS_LOAD_SUCCESS,
    payload: { data, key, parameters }
  });

  subscribersSuccess = (data, key, parameters) => ({
    type: AccountsTypes.SUBSCRIBERS_LOAD_SUCCESS,
    payload: { data, key, parameters }
  });

  employersSuccess = (data, key, parameters) => ({
    type: AccountsTypes.EMPLOYERS_LOAD_SUCCESS,
    payload: { data, key, parameters }
  });

  employersPaginate = (start, limit) => ({
    type: AccountsTypes.EMPLOYERS_PAGINATE,
    payload: { start, limit }
  });

  saveSuccess = (data, type) => ({
    type,
    payload: data
  });

  employersShowing = (showing) => ({
    type: AccountsTypes.EMPLOYERS_SHOWING,
    payload: showing
  });

  resetData = () => ({
    type: AccountsTypes.RESET_DATA
  });

  saveStart = () => ({
    type: AccountsTypes.START_SAVE
  });

  setCount = (table, count) => ({
    type: AccountsTypes.SET_COUNT,
    payload: { table, count }
  });

  wipeStart = (id) => ({
    type: AccountsTypes.WIPE_START,
    payload: id
  });

  wipeSuccess = () => ({
    type: AccountsTypes.WIPE_SUCCESS
  });

  setTargetSite = (target: string) => {
    if (target === 'prod') {
      console.log('** Seting environment to prod');
      Amplify.configure(environment.amplifyprod);
    }
    if (target === 'dev') {
      console.log('** Seting environment to dev');
      Amplify.configure(environment.amplifydev);
    }
    return {
      type: AccountsTypes.SET_TARGET_SITE,
      payload: target
    };
  };

  usersUpdateSort = (sortField, sortDirection, limit) => ({
    type: AccountsTypes.USERS_UPDATE_SORT,
    payload: { sortField, sortDirection, limit }
  });

  usersLoadingStart = () => ({
    type: AccountsTypes.USERS_LOADING_START
  });

  loadChaptersAsync = (user: string) => {
    return (dispatch) => {
      API.get(this.apiName, '/cms/chapters/' + user, {})
        .then((response) => {
          console.log(response);
          dispatch(this.chaptersSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.failure(StringHelper.extractError(error)));
        });
    };
  };

  loadAccountAsync = (type: string, id: string) => {
    console.log(` 🍰 Loading ${type} ${id}`);
    return (dispatch) => {
      dispatch(this.start(type));

      API.get(this.apiName, `/${type}s/${id}`, {})
        .then((response) => {
          console.log(response);
          dispatch(this.saveSuccess(response.data, `${type.toUpperCase()}_LOAD_SUCCESS`));
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.failure(StringHelper.extractError(error)));
        });

      // Load extra items
      switch (type) {
        case 'user':
          API.get(this.apiName, `/users-tokens/${id}`, {})
            .then((response) => {
              console.log(response);
              dispatch(this.saveSuccess(response.data, AccountsTypes.USER_LOAD_TOKEN_SUCCESS));
            })
            .catch((error) => {
              console.log(error);
              dispatch(this.failure(StringHelper.extractError(error)));
            });
          API.get(this.apiName, `/users-orders/${id}`, {})
            .then((response) => {
              console.log(response);
              dispatch(this.saveSuccess(response.data, AccountsTypes.USER_LOAD_ORDER_SUCCESS));
            })
            .catch((error) => {
              console.log(error);
              dispatch(this.failure(StringHelper.extractError(error)));
            });
          break;
        case 'subscriber':
          API.get(this.apiName, `/subscribers/${id}/codes`, {})
            .then((response) => {
              console.log(response);
              dispatch(
                this.saveSuccess(response.data, AccountsTypes.SUBSCRIBER_LOAD_CODES_SUCCESS)
              );
            })
            .catch((error) => {
              console.log(error);
              dispatch(this.failure(StringHelper.extractError(error)));
            });
          API.get(this.apiName, `/${type}s/${id}/packages`, {})
            .then((response) => {
              console.log(response);
              dispatch(
                this.saveSuccess(response.data, AccountsTypes.SUBSCRIBER_LOAD_PACKAGES_SUCCESS)
              );
            })
            .catch((error) => {
              console.log(error);
              dispatch(this.failure(StringHelper.extractError(error)));
            });
          break;
      }
    };
  };

  loadAccountsAsync = (table: string, parametersOverride?: IParameters) => {
    console.log(` 🍰 Loading ${table}`);
    return (dispatch, state) => {
      const userState = state().accounts;
      const parameters = parametersOverride ? parametersOverride : userState[`${table}Parameters`];
      const index = parameters.index;
      console.log(index);
      dispatch(this.start(table));
      if (index >= 1 && userState[`${table}Keys`] && userState[`${table}Keys`][index - 1]) {
        parameters.next = userState[`${table}Keys`][index - 1];
      } else {
        parameters.next = undefined;
      }
      this.fetchRecords(dispatch, table, parameters);
    };
  };

  fetchRecords = (dispatch, table, parameters: IParameters) => {
    dispatch(this.start(table));

    // Searching /webbi/users /webbi/subscribers and /webbi/employers here
    API.get(this.apiName, `/${table}`, { queryStringParameters: parameters })
      .then((response) => {
        switch (table) {
          case 'api':
            return dispatch(this.apiSuccess(response.data, response.key, parameters));
          case 'users':
            return dispatch(this.usersSuccess(response.data, response.key, parameters));
          case 'subscribers':
            return dispatch(this.subscribersSuccess(response.data, response.key, parameters));
          case 'employers':
            return dispatch(this.employersSuccess(response.data, response.key, parameters));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(this.failure(StringHelper.extractError(error)));
      });
  };

  loadCountAsync = (table: string) => {
    return (dispatch) => {
      API.get(this.apiName, `/table-count/${table}`, {})
        .then((response) => {
          dispatch(this.setCount(table, response.data));
        })
        .catch((error) => {
          dispatch(this.failure(StringHelper.extractError(error)));
        });
    };
  };

  saveAsync = (id: string, type: string, data: any) => {
    console.log(` 💾 Saving ${type}`);
    return (dispatch) => {
      dispatch(this.start(type));

      API.post(this.apiName, `/${type}s/${id}`, { body: data })
        .then((response) => {
          console.log(response);
          dispatch(this.saveSuccess(response.data, `${type.toUpperCase()}_LOAD_SUCCESS`));
        })
        .catch((error) => {
          dispatch(this.failure(StringHelper.extractError(error)));
        });
    };
  };

  fetchCertificate = (id: string) => {
    console.log(` 💾 Getting certificate for  ${id}`);
    return API.get(this.apiName, `/certificate-pdf/${id}`, {});
  };

  resendCertificateEmail = (data: { user_id: string; token_id: string }) => {
    console.log(` 💾 resendCertificateEmail for  ${data.user_id}`);
    return API.post(this.apiName, `/certificate-resend`, { body: data });
  };

  resendWallet = (data: { user_id: string; token_id: string }) => {
    console.log(` 💾 resendWallet for  ${data.user_id}`);
    return API.post(this.apiName, `/wallet-resend`, { body: data });
  };

  deleteToken = (id: string) => {
    console.log(` 💾 Deleting token ${id}`);
    return API.get(this.apiName, `/users-delete-token/${id}`, {});
  };

  runSearchAsync = (search: string, path: string) => {
    console.log(` 🔍 Searching for ${search} in "${path}"`);
    return (dispatch, state) => {
      let parameters;
      const accountState: IAccountsState = state().accounts;
      switch (path) {
        case '/accounts/employers':
          parameters = accountState.employersParameters;
          parameters.search = search;
          dispatch(this.loadAccountsAsync('employers', parameters));
          break;
        case '/accounts/subscribers':
          parameters = accountState.subscribersParameters;
          parameters.search = search;
          dispatch(this.loadAccountsAsync('subscribers', parameters));
          break;
        case '/accounts/users':
          parameters = accountState.usersParameters;
          parameters.search = search;
          dispatch(this.loadAccountsAsync('users', parameters));
          break;
      }
    };
  };

  generateCodesAsync = (
    id: string,
    data: { manager_id: string; employer_id: string; course: string; quantity: number }
  ) => {
    console.log(` 💾 Generating codes for ${id}`);
    return (dispatch) => {
      dispatch(this.start('subscriber'));

      API.post(this.apiName, `/subscribers-codes-generate/${id}`, { body: data })
        .then((response) => {
          console.log(response);
          dispatch(<AnyAction>(<unknown>this.loadAccountAsync('subscriber', id)));
        })
        .catch((error) => {
          dispatch(this.failure(StringHelper.extractError(error)));
        });
    };
  };

  makeSubscriber = (data: any) => {
    console.log(` 💾 makeSubscriber`);
    return (dispatch) => {
      API.post(this.apiName, `/subscriber-create`, { body: { id: data.id } })
        .then((response) => {
          console.log(response);
          dispatch(this.loadAccountAsync('user', data.id));
        })
        .catch((error) => {
          dispatch(this.failure(StringHelper.extractError(error)));
        });
    };
  };

  wipeTokenAsync = (id: string) => {
    console.log(` 💾 wipeTokenAsync`);

    return (dispatch) => {
      dispatch(this.wipeStart(id));
      API.post(this.apiName, `/token/wipe`, { body: { id } })
        .then((response) => {
          console.log(response);
          dispatch(this.wipeSuccess());
        })
        .catch((error) => {
          dispatch(this.failure(StringHelper.extractError(error)));
        });
    };
  };
}

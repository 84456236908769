import { Injectable } from '@angular/core';
import Amplify, { API } from 'aws-amplify';
import { environment } from '../../../../environments/environment';
import { CoursesTypes } from './courses.types';
import { Page, Question } from './courses.state';
import { StringHelper } from '../../../shared/string-helper';
import { AnyAction } from 'redux';

@Injectable()
export class CoursesActions {
  apiName = 'fs-api';

  start = () => ({
    type: CoursesTypes.START
  });

  chaptersSuccess = (data) => ({
    type: CoursesTypes.CHAPTERS_LOAD_SUCCESS,
    payload: data
  });

  clearMessage = () => ({
    type: CoursesTypes.CLEAR_MESSAGE
  });

  coursesSuccess = (data) => ({
    type: CoursesTypes.COURSES_LOAD_SUCCESS,
    payload: data
  });

  currentCourse = (data) => ({
    type: CoursesTypes.CURRENT_COURSE,
    payload: data
  });

  failure = (data) => ({
    type: CoursesTypes.FAILURE,
    payload: data
  });

  pageSetEditId = (data) => ({
    type: CoursesTypes.PAGE_SET_EDIT_ID,
    payload: data
  });

  pagesSuccess = (data) => ({
    type: CoursesTypes.PAGES_LOAD_SUCCESS,
    payload: data
  });

  pagesPaginate = (start, limit) => ({
    type: CoursesTypes.PAGES_PAGINATE,
    payload: { start, limit }
  });

  pageSaveSuccess = (data) => ({
    type: CoursesTypes.PAGES_SAVE_SUCCESS,
    payload: data
  });

  pagesShowing = (showing) => ({
    type: CoursesTypes.PAGES_SHOWING,
    payload: showing
  });

  pagesUpdateSort = (sortField, sortDirection) => ({
    type: CoursesTypes.PAGES_UPDATE_SORT,
    payload: { sortField, sortDirection }
  });

  questionEditSet = (data) => ({
    type: CoursesTypes.QUESTION_EDIT,
    payload: data
  });

  questionsSuccess = (data) => ({
    type: CoursesTypes.QUESTIONS_LOAD_SUCCESS,
    payload: data
  });

  questionsPaginate = (start, limit) => ({
    type: CoursesTypes.QUESTIONS_PAGINATE,
    payload: { start, limit }
  });

  saveSuccess = (data, type) => ({
    type,
    payload: data
  });

  questionsShowing = (showing) => ({
    type: CoursesTypes.QUESTIONS_SHOWING,
    payload: showing
  });

  questionsStart = () => ({
    type: CoursesTypes.QUESTIONS_START
  });

  questionsUpdateSort = (sortField, sortDirection) => ({
    type: CoursesTypes.QUESTIONS_UPDATE_SORT,
    payload: { sortField, sortDirection }
  });

  resetData = () => ({
    type: CoursesTypes.RESET_DATA
  });

  saveStart = () => ({
    type: CoursesTypes.START_SAVE
  });
  endSave = () => ({
    type: CoursesTypes.END_SAVE
  });

  sectionsSuccess = (data) => ({
    type: CoursesTypes.SECTIONS_LOAD_SUCCESS,
    payload: data
  });

  setTargetSite = (target: string) => {
    if (target === 'prod') {
      console.log('** Seting environment to prod');
      Amplify.configure(environment.amplifyprod);
    }
    if (target === 'dev') {
      console.log('** Seting environment to dev');
      Amplify.configure(environment.amplifydev);
    }
    return {
      type: CoursesTypes.SET_TARGET_SITE,
      payload: target
    };
  };

  // profileSaveStart = () => ({
  //   type: ReportTypes.PROFILE_START_SAVE
  // });
  //
  // profileSaveSuccess = (objUser: any) => ({
  //   type: ReportTypes.PROFILE_SAVE_SUCCESS,
  //   payload: objUser
  // });
  //
  // profileSaveFailure = (error) => ({
  //   type: ReportTypes.PROFILE_SAVE_FAILURE,
  //   payload: error
  // });

  loadChaptersAsync = (course: string) => {
    return (dispatch) => {
      API.get('webbi-courses', '/chapters/' + course, {})
        .then((response) => {
          dispatch(this.chaptersSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.failure(error.message));
        });
    };
  };

  loadCoursesAsync = () => {
    return (dispatch) => {
      dispatch(this.start());

      API.get('webbi-courses', '/courses', {})
        .then((response) => {
          console.log(response);
          dispatch(this.coursesSuccess(response.data.rows));
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.failure(error.message));
        });
    };
  };

  loadPagesAsync = (course: string) => {
    console.log(' 🍰 Loading Pages');
    return (dispatch) => {
      dispatch(this.currentCourse(course));
      dispatch(this.start());

      API.get('webbi-courses', '/pages/' + course, {})
        .then((response) => {
          console.log(response);
          dispatch(this.pagesSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.failure(error.message));
        });
    };
  };

  loadQuestionsAsync = (course: string) => {
    console.log(' 🍰 Loading Questions');
    return (dispatch) => {
      dispatch(this.currentCourse(course));
      dispatch(this.questionsStart());

      API.get('webbi-courses', '/questions/list/' + course, {})
        .then((response) => {
          console.log(response);
          dispatch(this.questionsSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.failure(error.message));
        });
    };
  };

  loadSectionsAsync = (course: string) => {
    return (dispatch) => {
      API.get('webbi-courses', '/sections/' + course, {})
        .then((response) => {
          console.log(response);
          dispatch(this.sectionsSuccess(response.data));
        })
        .catch((error) => {
          console.log(error);
          dispatch(this.failure(error.message));
        });
    };
  };

  publishAsync = (id: string, type: string, data: Question | Page) => {
    console.log(` 📦 Publishing ${type}`);
    return (dispatch) => {
      dispatch(this.saveStart());

      API.post('webbi-courses', `/${type}-publish/${id}`, { body: data })
        .then((result) => {
          console.log(result);
          switch (type) {
            case 'question':
              dispatch(this.saveSuccess(result.data, CoursesTypes.QUESTIONS_SAVE_SUCCESS));
              break;
            case 'page':
              dispatch(this.saveSuccess(result.data, CoursesTypes.PAGES_SAVE_SUCCESS));
              break;
            case 'course':
              dispatch(this.saveSuccess(result.data, CoursesTypes.COURSE_PUBLISH_SUCCESS));
              break;
          }
        })
        .catch((error) => {
          dispatch(this.failure(StringHelper.extractError(error)));
        });
    };
  };

  saveAsync = (id: string, type: string, data: Question | Page) => {
    console.log(` 💾 Saving ${type}`);
    return (dispatch) => {
      dispatch(this.saveStart());

      API.post('webbi-courses', `/${type}/edit/${id}`, { body: data })
        .then((result) => {
          console.log(result);
          switch (type) {
            case 'course':
              dispatch(this.saveSuccess(result.data, CoursesTypes.COURSE_SAVE_SUCCESS));
              break;
            case 'question':
              dispatch(this.saveSuccess(result.data, CoursesTypes.QUESTIONS_SAVE_SUCCESS));
              break;
            case 'page':
              dispatch(this.saveSuccess(result.data, CoursesTypes.PAGES_SAVE_SUCCESS));
              break;
          }
        })
        .catch((error) => {
          dispatch(this.failure(StringHelper.extractError(error)));
        });
    };
  };

  saveSequenceNumbersAsync = (course: string, data: any) => {
    console.log(` 💾 Saving Sequence #'s for ${course}`);
    return (dispatch) => {
      dispatch(this.saveStart());

      API.post('webbi-courses', `/page-sequence/${course}`, { body: data })
        .then((result) => {
          console.log(result);
          dispatch(<AnyAction>(<unknown>this.loadPagesAsync(course)));
          dispatch(this.endSave());
        })
        .catch((error) => {
          dispatch(this.failure(StringHelper.extractError(error)));
        });
    };
  };
}

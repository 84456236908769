import { INITIAL_STATE } from './accounts.state';
import { AccountsTypes } from './accounts.types';
import {
  filterEmployersByShowing,
  sortEmployers,
  updateAccount,
  upsertAccount
} from './accounts.utils';

const accountsReducer = (state = INITIAL_STATE, action) => {
  let sortedData;
  let parameters;
  switch (action.type) {
    case AccountsTypes.CHAPTERS_LOAD_SUCCESS:
      return {
        ...state,
        chapters: action.payload
      };
    case AccountsTypes.FAILURE:
      return {
        ...state,
        loading: undefined,
        saving: false,
        error: action.payload
      };
    case AccountsTypes.EMPLOYER_LOAD_SUCCESS:
      return {
        ...state,
        loading: undefined,
        error: undefined,
        employer: action.payload
      };
    case AccountsTypes.EMPLOYERS_LOAD_SUCCESS:
      const employersKeys = state.employersKeys;
      if (action.payload.key) {
        employersKeys[action.payload.parameters.index] = action.payload.key;
      }
      return {
        ...state,
        loading: undefined,
        employers: action.payload.data,
        employersKeys,
        employersParameters: action.payload.parameters,
        error: undefined
      };
    case AccountsTypes.EMPLOYERS_PAGINATE:
      return {
        ...state,
        employersParameters: {
          ...state.employersParameters,
          start: action.payload.start,
          limit: action.payload.limit
        }
      };
    case AccountsTypes.EMPLOYERS_SHOWING:
      parameters = state.employersParameters;
      parameters.showing = action.payload;
      return {
        ...state,
        employersFiltered: filterEmployersByShowing(state.employers, parameters),
        employersParameters: {
          ...state.employersParameters,
          start: 0,
          showing: action.payload
        }
      };
    case AccountsTypes.EMPLOYERS_SAVE_SUCCESS:
      sortedData = sortEmployers(
        upsertAccount(state.employers, action.payload),
        state.employersParameters
      );
      return {
        ...state,
        saving: false,
        error: undefined,
        employers: sortedData,
        employersFiltered: filterEmployersByShowing(sortedData, state.employersParameters)
      };
    case AccountsTypes.EMPLOYERS_START:
      return {
        ...state,
        error: undefined,
        employers: undefined,
        employersFiltered: undefined
      };
    case AccountsTypes.EMPLOYERS_UPDATE_SORT:
      parameters = state.employersParameters;
      parameters.sortDirection = action.payload.sortDirection;
      parameters.sortField = action.payload.sortField;
      parameters.start = 0;

      sortedData = sortEmployers(state.employers, parameters);
      return {
        ...state,
        employers: sortedData,
        employersFiltered: filterEmployersByShowing(sortedData, parameters),
        employersParameters: parameters
      };
    case AccountsTypes.RESET_DATA:
      return {
        ...state,
        error: undefined,
        employers: undefined,
        employersCount: undefined,
        employersParameters: INITIAL_STATE.employersParameters,
        subscribers: undefined,
        subscribersCount: undefined,
        subscribersParameters: INITIAL_STATE.subscribersParameters,
        users: undefined,
        usersCount: undefined,
        usersParameters: INITIAL_STATE.usersParameters,
        usersKeys: {}
      };
    case AccountsTypes.SET_COUNT:
      console.log(action.payload);
      console.log(`${action.payload.table}Count`);
      return {
        ...state,
        error: undefined,
        [`${action.payload.table}Count`]: action.payload.count
      };
    case AccountsTypes.SET_TARGET_SITE:
      return {
        ...state,
        error: undefined
      };
    case AccountsTypes.START:
      return {
        ...state,
        loading: action.payload,
        error: undefined,
        user: action.payload === 'user' ? undefined : state.user,
        userTokens: action.payload === 'user' ? undefined : state.userTokens
      };
    case AccountsTypes.START_SAVE:
      return {
        ...state,
        saving: true
      };
    case AccountsTypes.SUBSCRIBER_LOAD_SUCCESS:
      sortedData = updateAccount(state.subscribers, action.payload);
      return {
        ...state,
        loading: undefined,
        error: undefined,
        subscriber: action.payload,
        subscribers: sortedData
      };
    case AccountsTypes.SUBSCRIBER_LOAD_CODES_SUCCESS:
      return {
        ...state,
        subscriberTokens: action.payload
      };
    case AccountsTypes.SUBSCRIBER_LOAD_PACKAGES_SUCCESS:
      return {
        ...state,
        subscriberPackages: action.payload
      };
    case AccountsTypes.SUBSCRIBERS_LOAD_SUCCESS:
      const subscribersKeys = state.subscribersKeys;
      if (action.payload.key) {
        subscribersKeys[action.payload.parameters.index] = action.payload.key;
      }
      console.log(action.payload);
      return {
        ...state,
        loading: undefined,
        subscribers: action.payload.data,
        subscribersKeys,
        subscribersParameters: action.payload.parameters,
        error: undefined
      };
    case AccountsTypes.APIS_LOAD_SUCCESS:
      const apisKeys = state.apisKeys;
      if (action.payload.key) {
        apisKeys[action.payload.parameters.index] = action.payload.key;
      }
      console.log(action.payload);
      return {
        ...state,
        loading: undefined,
        apis: action.payload.data,
        apisKeys,
        apisParameters: action.payload.parameters,
        error: undefined
      };
    case AccountsTypes.USER_LOAD_SUCCESS:
      sortedData = updateAccount(state.users, action.payload);
      return {
        ...state,
        loading: undefined,
        error: undefined,
        user: action.payload,
        users: sortedData
      };
    case AccountsTypes.USER_LOAD_ORDER_SUCCESS:
      return {
        ...state,
        userOrders: action.payload
      };
    case AccountsTypes.USER_LOAD_TOKEN_SUCCESS:
      return {
        ...state,
        userTokens: action.payload
      };
    case AccountsTypes.USERS_LOADING_START:
      return {
        ...state,
        usersLoading: true
      };
    case AccountsTypes.USERS_LOAD_SUCCESS:
      const usersKeys = state.usersKeys;
      if (action.payload.key) {
        usersKeys[action.payload.parameters.index] = action.payload.key;
      }
      return {
        ...state,
        loading: undefined,
        usersLoading: false,
        users: action.payload.data,
        usersKeys,
        usersParameters: action.payload.parameters,
        error: undefined
      };
    case AccountsTypes.USERS_SAVE_SUCCESS:
      return {
        ...state,
        loading: undefined,
        users: action.payload
      };
    case AccountsTypes.USERS_UPDATE_SORT:
      parameters = state.usersParameters;
      parameters.sortDirection = action.payload.sortDirection;
      parameters.sortField = action.payload.sortField;
      parameters.limit = action.payload.limit;
      parameters.start = 0;
      parameters.index = 0;
      return {
        ...state,
        users: undefined,
        usersParameters: parameters
      };
    case AccountsTypes.WIPE_START:
      return {
        ...state,
        wiping: action.payload
      };
    case AccountsTypes.WIPE_SUCCESS:
      return {
        ...state,
        wiping: undefined
      };
    default:
      return state;
  }
};

export default accountsReducer;

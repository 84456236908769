import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { OktaService } from '../../shared/service/okta-service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private oktaService: OktaService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      let isValid = await this.oktaService.getAuthClient().isAuthenticated();
      if (isValid) {
        return resolve(true);
      }
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } }).then();
      return resolve(false);
    });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import ReconnectingWebSocket, { Options } from 'reconnecting-websocket';
import { environment } from '../../../environments/environment';
import { select } from '@angular-redux/store';
import { selectTargetSite } from '../../modules/auth/redux/auth.selectors';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class WebsocketService {
  connected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  ws: ReconnectingWebSocket;
  @select(selectTargetSite) readonly targetSite$: Observable<string>;

  private subject: Subject<MessageEvent>;
  private amplifyEnv;

  constructor() {
    window.addEventListener('online', () => console.log('came online'));
    window.addEventListener('offline', () => {
      console.log('came offline');
      this.connected.next(false);
    });

    this.targetSite$.subscribe((target) => {
      this.amplifyEnv = target === 'dev' ? environment.amplifydev : environment.amplifyprod;
      if (this.subject) {
        console.log('Environment changed, reconnecting to ws');
        this.disconnect();
        this.connect();
      }
    });
  }

  public connect(endpoint = 'sockets'): Subject<MessageEvent> {
    console.log(`📞 WS connecting`);
    const url = this.amplifyEnv.API.endpoints.find((item) => item.name === endpoint).endpoint;
    if (!this.subject) {
      this.subject = this.create(url);
    }
    return this.subject;
  }

  public disconnect(): void {
    console.log('websocket disconnect()');
    if (this.ws) {
      this.ws.close(1000, 'Purposely disconnecting.');
    }
    this.subject = undefined;
    this.connected.next(false);
  }

  private create(url): Subject<MessageEvent> {
    const options: Options = {
      // debug: (process.env.APP_ENV !== 'prod');
    };
    this.ws = new ReconnectingWebSocket(url, [], options);

    this.ws.addEventListener('message', (event: MessageEvent) => {
      // console.log(event);
      this.subject.next(event);
    });

    this.ws.addEventListener('error', (event) => {
      console.error(event);
    });

    this.ws.addEventListener('close', (event) => {
      if (event.wasClean) {
        console.log(`Connection closed cleanly, code = ${event.code}`);
      }
      console.log('Event listener for close fired');
      this.connected.next(false);
    });

    return new Subject<MessageEvent>();
  }
}
